import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import data from "../../slider.json";
import "./Card.css";

const Card = () => {
  const settings = {
    dot: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  return (
    <div className="s-container">
      <Slider {...settings}>
        {data.map((card, i) => (
          <div className="r-card" key={i}>
            <img src={card.image} alt="home" />
            <span className="price"> $ {card.price}</span>
            <h1>{card.name}</h1>
            <span>{card.detail}</span>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Card;
