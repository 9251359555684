import React from "react";
import "./Header.css";

const Header = () => {
  return (
    <div className="header">
      <div className="image">
        <img src="logo.png" alt="home" />
      </div>

      <div className="links">
        <a href="#">Residencies</a>
        <a href="#">Our Value</a>
        <a href="#">Get Started</a>
        <a href="#">Contact Us</a>
        <button className="register-button">
          <a href="">Register</a>
        </button>
      </div>
    </div>
  );
};

export default Header;
