import React from "react";
import "./ContactUS.css";
import { IoIosCall } from "react-icons/io";
import { BiMessageRoundedDetail } from "react-icons/bi";

const ContactUS = () => {
  return (
    <>
      <div className="contact">
        <div className="left">
          <div className="title">
            <span>Our Contact</span>
            <h1>Easy To Connect</h1>
            <p>
              We always ready to help by providing the best services for you.
              <br /> We believe a good place to live can make your life better
            </p>
          </div>
          <div className="contact-info">
            <div className="upper">
              <div className="call">
                <span>
                  < IoIosCall />
                </span>
                <h1>
                  Call <br /> 123456789{" "}
                </h1>
              </div>
              <button>
                <div className="call-button">Call NOW</div>
              </button>
            </div>

            <div className="messages"></div>
            <div className="upper">
              <div className="call">
                <span>
                  <BiMessageRoundedDetail />
                </span>
                <h1>
                  Chat<br /> 123456789
                </h1>
              </div>
              <button>
                <div className="call-button">Chat Button</div>
              </button>
            </div>
            <div className="lower"></div>
          </div>
        </div>

        <div className="right">
          <img src="Contact.jpg" alt="" />
        </div>
      </div>
    </>
  );
};

export default ContactUS;
