import React from "react";
import "./Value.css";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { AiFillSafetyCertificate } from "react-icons/ai";

const Value = () => {
  return (
    <div className="value-container">
      <div className="left-section">
        <img src="value.png" alt="" width={"25rem"} height={"25rem"} />
      </div>
      <div className="right-section">
        <h2>Our Value</h2>
        <h1>Value We Give to You</h1>
        <span>
          We always ready to help by providijng the best services for you.{" "}
          <br />
          We beleive a good blace to live can make your life better
        </span>
        <div className="information">
          <div className="info-head">
            <h1>
              <AiFillSafetyCertificate />
            </h1>
            <span>Best interest rates on the market</span>
            <h1>
              <IoIosArrowDropdownCircle />
            </h1>
          </div>
          <span>
            Exercitation in fugiat est ut ad ea cupidatat ut in cupidatat
            occaecat ut occaecat consequat est minim minim esse tempor laborum
            consequat esse adipisicing eu reprehenderit enim.
          </span>
        </div>
        <div className="information">
          <div className="info-head">
            <h1>
              <AiFillSafetyCertificate />
            </h1>
            <span>Prevent unstable prices</span>
            <h1>
              <IoIosArrowDropdownCircle />
            </h1>
          </div>
          <span>
            Exercitation in fugiat est ut ad ea cupidatat ut in cupidatat
            occaecat ut occaecat consequat est minim minim esse tempor laborum
            consequat esse adipisicing eu reprehenderit enim.
          </span>
        </div>
        <div className="information">
          <div className="info-head">
            <h1>
              <AiFillSafetyCertificate />
            </h1>
            <span>Best Price on the market</span>
            <h1>
              <IoIosArrowDropdownCircle />
            </h1>
          </div>
          <span>
            Exercitation in fugiat est ut ad ea cupidatat ut in cupidatat
            occaecat ut occaecat consequat est minim minim esse tempor laborum
            consequat esse adipisicing eu reprehenderit enim.
          </span>
        </div>
      </div>
    </div>
  );
};

export default Value;
