import React from "react";
import "./Hero.css";
import { TiLocation } from "react-icons/ti";
import Card from './../PropertyCard/Card';

const Hero = () => {
  return (
    <>
      <section className="hero-wrapper">
        <div className="left-section">
          <h1 className="mainHeading">
            Discover
            <br />
            Most Suitable
            <br />
            Property
          </h1>

          <span>Find a variety of properties that suit you very easilty</span>
          <span>Forget all difficulties in finding a residence for you</span>

          {/* search bar */}

          <div className="search-bar">
            <div className="icon">
              <TiLocation />
            </div>
            <input
              className="input"
              type="text"
              placeholder="Search your Dream Property"
            />
            <button className="search-btn">Search</button>
          </div>

          {/* circles */}

          <div className="circle"></div>
          <div className="bg-circle"></div>
        </div>

        {/* image section */}
        <div className="display-image">
          <img src="hero-image.png" alt="" />
        </div>
      </section>

      <div className="img-tags">
        <img src="prologis.png" alt="" />
        <img src="realty.png" alt="" />
        <img src="tower.png" alt="" />
        <img src="equinix.png" alt="" />
      </div>

      <section>
        <div className="choices">
          <span>Best Choices</span>
          <div className="residencies">
            <h1>Popular Residencies</h1>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
